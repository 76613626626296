<template>
  <div>
    <!-- Filter -->
    <div class="row align-items-center justify-content-start mt-n3">
      <div class="col-md-6">
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('days')"
          v-bind:class="{
            'btn-info': btnActive.days,
            'btn-outline-info': btnActive.days == false,
          }">
          Hari Ini
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('month')"
          v-bind:class="{
            'btn-info': btnActive.month,
            'btn-outline-info': btnActive.month == false,
          }">
          Bulan Ini
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('lastMonth')"
          v-bind:class="{
            'btn-info': btnActive.lastMonth,
            'btn-outline-info': btnActive.lastMonth == false,
          }">
          Bulan Kemarin
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('year')"
          v-bind:class="{
            'btn-info': btnActive.year,
            'btn-outline-info': btnActive.year == false,
          }">
          Tahun Ini
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          v-bind:class="{
            'btn-info': btnActive.period,
            'btn-outline-info': btnActive.period == false,
          }"
          @click="btnPeriodeOnClick">
          Periode
        </button>
      </div>

      <div class="col-md-2">
        <treeselect
          v-model="filter.doctor_id"
          :multiple="false"
          :options="doctors"
          @select="filterByDoctor"
          placeholder="Cari Berdasar Dokter" />
      </div>
      <div class="col-md-3">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.patient_name"
            placeholder="Cari Nama Pasien"
            @keyup="filterByPatient"></b-form-input>
        </b-input-group>
      </div>
      <div class="col-md-1">
        <b-form-select
          id="input-period"
          v-model="perPage"
          :options="formats"
          @change="pagination"
          placeholder="Per Halaman (Def. 20)"></b-form-select>
      </div>
    </div>

    <div class="row justify-content-between mt-2">
      <div class="col-md-6" v-if="onShowPeriod">
        <span class="d-none d-sm-block">
          <b-input-group>
            <b-form-input
              v-model="filter.start_date"
              type="text"
              placeholder="Tanggal Awal"
              autocomplete="off"
              readonly></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.start_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"></b-form-datepicker>
            </b-input-group-append>
            <b-button squared variant="light">s/d</b-button>
            <b-form-input
              v-model="filter.end_date"
              type="text"
              placeholder="Tanggal Akhir"
              autocomplete="off"
              readonly></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.end_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"></b-form-datepicker>
            </b-input-group-append>
            <template #append>
              <b-button squared @click="generalFilter" variant="success">Cari</b-button>
              <b-button squared @click="resetFilter" variant="danger">Reset</b-button>
            </template>
          </b-input-group>
        </span>
        <span class="d-block d-sm-none">
          <div class="row">
            <div class="col-12 pb-1">
              <b-input-group>
                <b-form-input
                  v-model="filter.start_date"
                  type="text"
                  placeholder="Tanggal Awal"
                  autocomplete="off"
                  readonly></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.start_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-12 pt-0">
              <b-input-group>
                <b-button squared variant="light">s/d</b-button>
                <b-form-input
                  v-model="filter.end_date"
                  type="text"
                  placeholder="Tanggal Akhir"
                  autocomplete="off"
                  readonly></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.end_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"></b-form-datepicker>
                </b-input-group-append>
                <template #append>
                  <b-button squared @click="resetFilter" variant="danger">Reset</b-button>
                  <b-button squared @click="generalFilter" variant="success">Cari</b-button>
                </template>
              </b-input-group>
            </div>
          </div>
        </span>
      </div>

      <div class="col-6" v-if="onShowPeriod == false"></div>

      <div class="col-4 d-flex justify-content-end">
        <button
          class="btn btn-success mr-1"
          @click="$router.push('/report/payment-chart')">
          Grafik <i class="fas fa-chart-bar px-0 ml-1"></i>
        </button>
        <button class="btn btn-primary mr-1" @click="btnExportOnClick">
          Ekspor <i class="fas fa-print px-0 ml-1"></i>
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-start align-items-center mt-5">
      <!-- <button
        class="btn btn-primary mr-1"
        v-b-tooltip.hover
        title="Print"
        @click="btnPrintOnClick"
      >
        <i class="fas fa-print px-0"></i>
      </button>
      <button
        class="btn btn-info mr-1"
        v-b-tooltip.hover
        title="Download Pdf"
        @click="btnDownloadOnClick"
      >
        <i class="fas fa-file-download px-0"></i>
      </button>
      <button
        class="btn btn-warning mr-1"
        v-b-tooltip.hover
        title="Download Excel"
        @click="btnExcelOnClick"
      >
        <i class="fas fa-file-excel px-0"></i>
      </button> -->
    </div>

    <div class="row justify-content-between align-items-center mt-6">
      <div class="col-md-4">
        <span
          style="width: 150px; color: #245590 !important"
          class="font-weight-bold">{{ recordData }} Baris Data</span>
      </div>
      <div class="col-md-6 d-flex justify-content-end">
        <span
          style="width: 150px; color: #245590 !important"
          class="font-weight-bold">Total Terbayar:</span>
        <span class="font-weight-bold" style="color: #245590 !important">{{
          "Rp. " + parseInt(summary).toLocaleString("id-ID")
        }}</span>
      </div>
      <!-- <span class="font-weight-bold" style="color: #245590 !important">{{
      //   summary.length != 0
      //     ? "Rp. " + parseInt(summary[0].final_amount).toLocaleString("id-ID")
      //     : ""
       }}</span> -->
    </div>

    <!-- Table -->
    <b-table
      striped
      class="mt-3"
      hover
      :items="items"
      :fields="fields"
      responsive
      :style="'white-space: nowrap'">
      <template #cell(medical_list)="data">
        <div v-for="(service, index) in data.item.services" :key="index">
          <li>
            {{
              `${service.name} : ${parseInt(service.price).toLocaleString(
                "id-ID"
              )}`
            }}
          </li>
        </div>
      </template>
      <template #cell(total_amount)="data">
        {{ parseInt(data.item.total_amount).toLocaleString("id-ID") }}
      </template>
      <template #cell(tax)="data">
        {{ parseInt(data.item.tax).toLocaleString("id-ID") }}
      </template>
      <template #cell(discount)="data">
        {{ parseInt(data.item.discount).toLocaleString("id-ID") }}
      </template>
      <template #cell(final_amount)="data">
        <ul>
          <li>
            Total :
            {{ parseInt(data.item.final_amount).toLocaleString("id-ID") }}
          </li>
          <li>
            Terbayar :
            {{ parseInt(data.item.paid_amount).toLocaleString("id-ID") }}
          </li>
        </ul>
      </template>
      <template #cell(surcharge)="data">
        {{ parseInt(data.item.surcharge).toLocaleString("id-ID") }}
      </template>
    </b-table>

    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"></b-pagination>

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="'Laporan Pendapatan Rumah Sakit'"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="780px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf">
      <section slot="pdf-content">
        <Print
          :items="reportData"
          :summary="summary"
          :perPage="perPage"
          :currentPage="currentPage"
          :totalRows="totalRows" />
      </section>
    </vue-html2pdf>

    <ModalReport
      :reportType="'payment-report'"
      :reportName="'Pendapatan Klinik'"
      :dataModal="reportData"
      :excel="excelNew" />
  </div>
</template>

<script>

import XLSX from "xlsx";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { debouncer } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js";
import Print from "@/component/reports-print/PaymentReport.vue";
import VueHtml2pdf from "vue-html2pdf";
import ModalReport from "@/component/general/ModalReport.vue";
import validation from "@/core/modules/ValidationModule.js";
import moment from "moment";

export default {
  props: {
    // hasPatient: Boolean,
    // items: Array,
    // summary: Array,
    // perPage: Number,
    // currentPage: Number,
    // totalRows: Number,
  },

  components: {
    Treeselect,
    XLSX,
    VueHtml2pdf,
    Print,
    ModalReport,
    // moment,
  },

  data() {
    return {
      // Filter
      filter: {
        doctor_id: "",
        patient_name: "",
        start_date: "",
        end_date: "",
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "days",
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      recordData: 0,
      // Table Items
      items: [],
      listData: [],
      summary: 0,
      excelData: [],
      // Options
      doctors: [],
      // field
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_created_at",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: true,
        },

        {
          key: "medical_list",
          label: "Daftar Layanan",
          sortable: true,
        },
        {
          key: "total_amount",
          label: "Subtotal",
          sortable: true,
        },
        {
          key: "tax",
          label: "Pajak",
          sortable: true,
        },
        {
          key: "discount",
          label: "Diskon",
          sortable: true,
        },
        {
          key: "surcharge",
          label: "Surcharge",
          sortable: true,
        },
        {
          key: "final_amount",
          label: "Total",
          sortable: true,
        },
        {
          key: "notes",
          label: "Catatan",
          sortable: true,
        },
      ],
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
      reportData: [],

      // eexcel new data
      excelNew: []
    };
  },

  methods: {
    async getDoctorsOption() {
      let response = await module.setDoctorTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({
          label: "Filter Berdasar Dokter",
          id: "",
          isDisabled: true,
        });
      }
    },

    resetFilter() {
      this.filter.doctor_id = "";
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.btnActiveOnClick(this.lastPeriodType);
      this.pagination();
    },

    btnExcelOnClick() {
      const data = XLSX.utils.json_to_sheet(this.excelNew);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Laporan Pendapatan Rumah Sakit.xlsx");
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      // pagination display
      let filterParams = `&patient_name=${this.filter.patient_name}&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "payment-reports",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      let pagination = response.meta.pagination;
      let f, g;
      g = this.perPage * (this.currentPage - 1) + 1;
      for (f = 0; f < response.data.length; f++) {
        response.data[f].number = g + f;
      }

      this.items = response.data;
      this.totalRows = pagination.total;
      this.recordData = pagination.total;

      // summary
      let filterReportParams = `patient_name=${this.filter.patient_name}&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let responseReport = await module.get('payment-report-no-paginations', `?${filterReportParams}`)

      this.summary = 0;
      responseReport.forEach(el => {
        let sum = el.paid_amount - el.change_amount
        this.summary = this.summary + sum
      });



    },

    generalFilter() {
      // this.filter.doctor_id = sended.doctor_id
      // this.filter.start_date = sended.start_date
      // this.filter.end_date = sended.end_date
      // this.filter.patient_name = sended.patient_name
      this.pagination();
    },

    async filterByDoctor() {
      await this.filter.doctor_id;
      this.pagination();
    },

    filterByPatient() {
      debouncer(() => {
        this.filter.start_date = "";
        this.filter.end_date = "";
        if (this.filter.patient_name == "") {
          this.btnActiveOnClick(this.lastPeriodType);
        }
        this.pagination();
      }, 500)
    },

    btnPrintOnClick() {
      this.$htmlToPaper("print");
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf();
    },

    onProgress($event) {
      console.log("onProgress", $event);
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration");
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event);
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "days";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "month";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "lastMonth";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        this.lastPeriodType = "year";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }
    },

    async btnExportOnClick() {

      SwalLoading.fire()

      let filterReportParams = `patient_name=${this.filter.patient_name}&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;

      let responseReport = await module.get(
        "payment-report-no-paginations",
        `?${filterReportParams}`
      );
      let responseExcel = await module.get(
        "excel-payment-reports",
        `?${filterReportParams}`
      );
      let responseNewExcel = await module.getNoNumber(
        "new-excel-payment-reports",
        `?${filterReportParams}`
      );

      this.excelData = responseExcel;
      this.excelNew = responseNewExcel;
      this.reportData = responseReport;


      await this.excelData.forEach((val, index) => {
        let service_list = "";

        // service
        if (val.Layanan != null) {
          val.Layanan.forEach((ser, m) => {
            // console.log(ser,"ser");
            if (service_list === "") {
              service_list = ser.name + " (" + parseInt(ser.price) + ")";
            } else {
              service_list =
                service_list +
                ", " +
                ser.name +
                " (" +
                parseInt(ser.price) +
                ")";
            }
          });
        } else {
          service_list = "";
        }

        this.excelData[index].Layanan = service_list;
        this.reportData[index].Layanan = service_list;
        // delete this.reportData[index].services
      });

      if (responseReport) {
        Swal.close()
        this.$bvModal.show("modal-report");
      }
    },
  },

  watch: {
    "filter.doctor_id": function (newVal, oldVal) {
      if (typeof newVal === "undefined") {
        this.filter.doctor_id = "";
        this.filterByDoctor();
      }
    },
  },

  mounted() {
    // Get Options
    this.pagination();
    this.getDoctorsOption();
  },
};
</script>

<style></style>